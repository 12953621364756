


<template>
    <div class="UserHome">
      <div class="bottomDiv">
        <div class="index_title" style="border-bottom: none">用户充值汇总</div>
        <div class="formDiv" style="width:80vw; margin-left: 8vw;">
            <el-form  style="width:50vw; margin-left: 5vw;">
                  <el-form-item label="手机号:">
                    <el-input
                    style="width:50vw"
                      v-model="FormData.phone"
                      placeholder="请输入用户名"
                    ></el-input>
                  </el-form-item>
                
                  <el-form-item style="width:50vw" label="运营商:">
                    <el-select v-model="FormData.username" placeholder="请选择运营商" >
                      <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                    </el-select>
                  </el-form-item>
              
                  <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
         
            </el-form>
          </div>
        <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height= "480px"
          style="
            width: 90%;
            margin: 20px auto;
            
            overflow-y: auto;
          "
        >
        <!-- <el-table-column label="id" type="index" width="60" /> -->
            <el-table-column label="用户编号" prop="user_number" />
            <el-table-column label="用户手机号" prop="phone" />
            <el-table-column label="充值总金额(元)" prop="sum_charge_amount" :formatter="rounding"/>
            <el-table-column label="充值次数" prop="frequency" />
            <!-- <el-table-column label="所属运营商" prop="username" /> -->
          </el-table>
          
        </div>          
          </div>
        
      <!-- </div> -->
    </div>
  </template>
  <script>
  import { onMounted, reactive, toRefs } from "vue-demi";
  import { user_charge_statistics,operator_data } from "@/request/api";
  import { ElMessage } from "element-plus";
  
  import ProvinceCityCountry from '../../assets/json/address'
  export default {
    name: "InvestCollect",
    setup() {
      const data = reactive({
        //表单数据
        FormData: {
          orderTime:['',''],
          operator_id : sessionStorage.getItem('userID'),
        },
        users:[],
        tableData: [
         
        ],
        isadmin:false,
        //   分页
        currentPage:1,
        pageSize:20,
        total:400,
        checked:0,
        small:true,
  
        loading: false,
        provinceData: ProvinceCityCountry.address,
      });
      const changeMonth = (value) =>{
        let date = new Date(value);
        let month = (date.getMonth() + 1).toString().padStart(2,'0');
        let year = date.getFullYear();
        data.FormData.startTime = year + '-' + month + '-01';
        let day = new Date(year,month,0);
        data.FormData.endTime = year + '-' + month + '-' + day.getDate();
        // console.log(startTime,'startTime');
        console.log(data.FormData.startTime,'0001');
        console.log(data.FormData.endTime,'0002');
        data.FormData.orderTime[0] = data.FormData.startTime
        data.FormData.orderTime[1] = data.FormData.endTime
        // console.log(endTime ,'endTime ');
  
      }
      const searchBtn = () => {
        getList();
      };
      const rounding=(row,column)=> {
        return parseFloat(row[column.property]?row[column.property]:'0').toFixed(2)
      }
      const getList = () => {
        
        // const stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0]: undefined;
        // const endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : undefined;
        const dataa = {
          operator_id : sessionStorage.getItem('userID'),
          username : data.FormData.username,
          phone: data.FormData.phone,
          start_execute_date : data.FormData.orderTime[0],
          stop_execute_date : data.FormData.orderTime[1],
        }
        
          data.loading = true;
          user_charge_statistics(dataa).then((res) => {
            console.log(res,'000999888');
            if (res) {
              data.loading = false;
              if (res.code == 200) {
                data.tableData = res.data;
                data.total = res.data.length
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("数据获取失败");
            }
          });
      };
      const getUsername = () => {
          const username1 = localStorage.getItem('username')
          const users1 = []
          operator_data().then((res) => {
            console.log(res,'sssssss');
            data.users = res
            res.forEach((a)=>{    
              users1.push(a.username)
          })
          if(users1.indexOf(username1)==-1){
            data.isadmin = true
          }else{
            data.isadmin = false
            data.FormData.username = username1
          }
          });
        };
      // const getTime = () => {
      //     var aData = new Date();
      //     const nowdate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
      //     data.FormData.orderTime[0] = nowdate
      //     data.FormData.orderTime[1] = nowdate
      //     console.log(data.FormData.orderTime,'data.order_timedata.order_time');
      //   }
  
        onMounted(() => {
          getUsername()
          getList();
            
            
        });
      return {
        ...toRefs(data),
        searchBtn,
        rounding,
        changeMonth,
        getList,
        getUsername
      };
    },
  };
  </script>
  <style scoped>
  
  .bottomDiv{
    height: 100%;
    background: #fff;
  }
  .my-label {
    background: var(--el-color-success-light-9);
  }
  .my-content {
    background: var(--el-color-danger-light-9);
    width: 100%;
  }
  .pagination{
      display: flex;
      justify-content: space-between;
      padding: 0 67px;
      margin-top: -10px;
  }
  
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
  </style>